import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface GoogleCaptchaProperties {
  captchaRef: React.MutableRefObject<ReCAPTCHA | null>;
}

export const GoogleCaptcha: React.FC<GoogleCaptchaProperties> = ({
  captchaRef,
}) => {
  const SITE_KEY =
    process.env.GOOGLE_CAPTCHA_SITE_KEY ||
    '6LfRK3AqAAAAAA-jb33vasHKN6FmtTM_f1x4Uduj';

  return (
    <ReCAPTCHA
      className="booking__captcha"
      ref={captchaRef}
      sitekey={SITE_KEY}
      size="normal"
    />
  );
};
