import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';

import './styles/PageTitleBackground.scss';

interface PageTitleBackgroundProps {
  title: string;
}

export const PageTitleBackground: React.FC<PageTitleBackgroundProps> = ({
  title,
}) => {
  return (
    <section className="page-title-background">
      <ContainerComponent>
        <div className="page-title-background__wrapper">
          <h1 className="page-title-background__title">{title}</h1>
        </div>
      </ContainerComponent>
    </section>
  );
};
