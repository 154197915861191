import * as Yup from 'yup';
import { nameRegExp } from './constants/nameRegExp';
import { phoneRegExp } from './constants/phoneRegExp';
import { checkPhoneDigits } from './helpers/checkPhoneDigits';

export const schemaBookingForm = Yup.object().shape({
  firstName: Yup.string()
    .required('Please write your first name')
    .matches(nameRegExp, 'To indicate only Latin letters')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  lastName: Yup.string()
    .required('Please write your last name')
    .matches(nameRegExp, 'To indicate only Latin letters')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  email: Yup.string()
    .email('Please write your email')
    .required('Please write your email'),
  phone: Yup.string()
    .matches(phoneRegExp, 'The phone must match the type: 123 456 7890')
    .test('Phone number must contain exactly 10 digits', checkPhoneDigits)
    .required('Please write your phone number'),
  useSms: Yup.boolean().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  apt: Yup.string().optional(),
  postal: Yup.string().optional(),
  province: Yup.string().required('Please choose your province'),
  serviceDate: Yup.string().optional(),
  frequencyId: Yup.number().optional(),
  cleaningType: Yup.string().optional(),
  squareFeet: Yup.string().optional(),
  bedrooms: Yup.string().optional(),
  washrooms: Yup.string().optional(),
  halfWashs: Yup.string().optional(),
  basement: Yup.string().optional(),
  garbage: Yup.string().optional(),
  instructions: Yup.string().optional(),
  home: Yup.string().optional(),
  parking: Yup.string().optional(),
  restriction: Yup.string().optional(),
  flexibility: Yup.string().optional(),
  occasion: Yup.string().optional(),
  aboutUs: Yup.string().optional(),
  discountCode: Yup.string().optional(),
});
