import React from 'react';
import { MapContainer } from 'react-leaflet';
import { MapAreas } from '../../modules/AreasMap';
import './styles/MapWrapper.scss';
import { SpecificArea } from '../../types';

interface MapWrapperProps {
  specificArea?: SpecificArea;
}

export const MapWrapper: React.FC<MapWrapperProps> = ({ specificArea }) => {
  return (
    <div className="map__wrapper">
      <MapContainer
        className="map__container"
        zoomControl={false}
        dragging={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        attributionControl={false}
        touchZoom={false}
        boxZoom={false}
        keyboard={false}
      >
        <MapAreas area="toronto" specificArea={specificArea} />
      </MapContainer>
    </div>
  );
};
