import { BookingFieldSet } from '../types/bookingFieldset';

export const fields: BookingFieldSet = {
  contactInformation: [
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'First Name',
    },
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Last Name',
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'example@test.com',
    },
    {
      name: 'phone',
      type: 'tel',
      placeholder: '123 456 7890',
    },
    {
      name: 'useSms',
      type: 'checkbox',
      placeholder: '',
    },
  ],
  locationInformation: [
    {
      name: 'address',
      type: 'text',
      placeholder: 'Address',
    },
    {
      name: 'apt',
      type: 'text',
      placeholder: 'Apt/Suite #',
    },
    {
      name: 'city',
      type: 'text',
      placeholder: 'City',
    },
    {
      name: 'postal',
      type: 'text',
      placeholder: 'Postal Code',
    },
  ],
  specialInstructions: [
    {
      type: 'text',
      placeholder: 'Elaborate on flexibility (optional)',
      name: 'flexibility',
    },
    {
      type: 'text',
      placeholder: 'Occasion for the cleaning (optional)',
      name: 'occasion',
    },
    {
      type: 'text',
      placeholder: 'How did you hear about us? (optional)',
      name: 'aboutUs',
    },
  ],
};
