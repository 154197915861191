import React from 'react';
import { NavLink } from 'react-router-dom';
import { SvgIconArrowDown } from '../../../../ui/SvgIcon';
import { getNameFromRoute } from '../../../../helpers';

import './styles/LinkWithDropdown.scss';
import { paths } from '../../../../constants';

interface LinkWithDropdownProps {
  route: string;
  isDropdownShown: boolean;
  setIsDropdownShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinkWithDropdown: React.FC<LinkWithDropdownProps> = ({
  route,
  setIsDropdownShown,
  isDropdownShown,
}) => {
  const {
    services: { children },
  } = paths;
  const title = getNameFromRoute(route);

  return (
    <>
      <NavLink
        className="menu__link dropdown-link"
        to={`${route}/${children.cleaningServices.path}`}
      >
        {title}
      </NavLink>
      <button
        className="dropdown-link__toggler"
        onClick={() => setIsDropdownShown(!isDropdownShown)}
      >
        <SvgIconArrowDown />
      </button>
    </>
  );
};
