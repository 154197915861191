import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { getAltTextForImage, getAreaURL } from '../../helpers';
import { areas } from '../../constants';

import map from '../../assets/areas-page/map-crystal-clear-toronto.png';
import './styles/AreasPageAreas.scss';

export const AreasPageAreas = () => {
  const altText = getAltTextForImage(map);

  return (
    <section className="areas-page-areas">
      <ContainerComponent>
        <div className="areas-page-areas__wrapper">
          <p className="areas-page-areas__desc">
            Book a cleaning service in your city below. We provide a range of
            house cleaning services across multiple cities in Canada. If you
            have any questions, reach out to our friendly customer service team!
          </p>
          {areas.map((area) => {
            return (
              <div key={area.name} className="areas-page-areas__content">
                <div className="areas-page-areas__province">{area.name}</div>
                <div className="areas-page-areas__province__container">
                  <div className="areas-page-areas__cities">
                    {area.cities.map((city) => {
                      const cityPath = getAreaURL(city.name);
                      return (
                        <div key={city.name} className="areas-page-areas__city">
                          <Link to={cityPath}>{city.name}</Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="areas-page-areas__img">
                    <img alt={altText} src={map} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ContainerComponent>
    </section>
  );
};
