import { Feature, Geometry } from 'geojson';
import { Layer } from 'leaflet';
import { NavigateFunction } from 'react-router-dom';
import { getAreaURL } from '../../../helpers';
import { colorPalette } from '../../../constants';

export const onEachArea = (
  feature: Feature<Geometry, any>,
  layer: Layer,
  navigate: NavigateFunction
) => {
  layer.on('mouseover', (event) => {
    event.target.setStyle({
      fillColor: colorPalette.secondaryColor,
    });
    layer.bindPopup(feature.properties.name);
    layer.openPopup();
  });

  layer.on('mouseout', (event) => {
    event.target.setStyle({
      fillColor: colorPalette.accentColor,
    });
    layer.closePopup();
    layer.unbindPopup();
  });

  layer.on('click', () => {
    const areaName = feature.properties.name;
    const URL = getAreaURL(areaName);
    navigate(`/${URL}`);
    layer.closePopup();
    layer.unbindPopup();
  });
};
