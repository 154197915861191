import { BookingPriceProvider } from '../context/BookingPriceRequestContext/bookingPriceContext';
import { BookingForm } from '../modules/BookingForm';
import { TitleBackground } from '../modules/TitleBackground';
import { MainWrapper as Main } from '../ui/MainWrapper';

import './styles/BookingPage.scss';

export const BookingPage = () => {
  return (
    <Main shownBreadCrumbs>
      <section className="booking-page">
        <TitleBackground
          title="View Pricing, Availability, and Book your Cleaning Below"
          isShapeShown
        />
        <BookingPriceProvider>
          <BookingForm />
        </BookingPriceProvider>
      </section>
    </Main>
  );
};
