import React, { ReactElement } from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { AreaCleaningItem } from './components/AreaCleaningItem/AreaCleaningItem';

import './styles/AreaCleaning.scss';
import { titleTextAllAreas, titleTextToronto } from './constants/titleText';
import { SpecificArea } from '../../types';

interface AreaCleaningProps {
  city: string;
  description: string;
  map: ReactElement;
}

export const AreaCleaning: React.FC<AreaCleaningProps> = ({
  city,
  description,
  map,
}) => {
  const title = city === 'Toronto' ? titleTextToronto : titleTextAllAreas;
  const areasToronto = Object.values(SpecificArea);
  return (
    <div className="area-cleaning">
      <ContainerComponent>
        <div className="area-cleaning__container">
          <div className="area-cleaning__text">
            <h2 className="area-cleaning__title">{title}</h2>
            <p className="area-cleaning__description">{description}</p>
            <ul className="area-cleaning__list">
              {areasToronto.map((area) => (
                <AreaCleaningItem key={area} area={area} />
              ))}
            </ul>
          </div>
          <div className="area-cleaning__map">{map}</div>
        </div>
      </ContainerComponent>
    </div>
  );
};
