import { Route, Routes } from 'react-router-dom';
import {
  HomePage,
  NorthYorkPage,
  NotFoundPage,
  TorontoPage,
  EtobicokePage,
  BookingPage,
  ThankYouPage,
  ContactUsPage,
  ReviewsPage,
  GiftCardsPage,
  AreasPage,
  ChecklistPage,
  CleaningServicePage,
  CondoCleaningPage,
  EcoCleaningPage,
  CleaningSeniorsPage,
  SpringCleaningPage,
  PostRenovationCleaningPage,
  MoveInCleaningPage,
  DeepCleaningPage,
} from '../pages';
import { paths } from '../constants';

export const RoutesApp = () => {
  const { services, areas } = paths;

  return (
    <Routes>
      <Route path={paths.root} element={<HomePage />} />
      <Route path={paths.book} element={<BookingPage />} />
      <Route path={paths.thankYou} element={<ThankYouPage />} />
      <Route path={paths.reviews} element={<ReviewsPage />} />
      <Route path={paths.giftCards} element={<GiftCardsPage />} />
      <Route path={paths.contactUs} element={<ContactUsPage />} />
      <Route path={paths.areas.path} element={<AreasPage />} />
      <Route
        path={`${areas.path}/${areas.children.northYork}`}
        element={<NorthYorkPage />}
      />
      <Route
        path={`${areas.path}/${areas.children.etobicoke}`}
        element={<EtobicokePage />}
      />
      <Route
        path={`${areas.path}/${areas.children.toronto}`}
        Component={TorontoPage}
      />
      <Route
        path={`${services.path}/${services.children.checklist.path}`}
        element={<ChecklistPage />}
      />
      <Route
        path={`${services.path}/${services.children.checklist.path}`}
        element={<ChecklistPage />}
      />
      <Route
        path={`${services.path}/${services.children.cleaningServices.path}`}
        element={<CleaningServicePage />}
      />
      <Route
        path={`${services.path}/${services.children.ecoCleaning.path}`}
        element={<EcoCleaningPage />}
      />
      <Route
        path={`${services.path}/${services.children.deepCleaning.path}`}
        element={<DeepCleaningPage />}
      />
      <Route
        path={`${services.path}/${services.children.seniorsCleaning.path}`}
        element={<CleaningSeniorsPage />}
      />
      <Route
        path={`${services.path}/${services.children.moveInCleaning.path}`}
        element={<SpringCleaningPage />}
      />
      <Route
        path={`${services.path}/${services.children.moveInCleaning.path}`}
        element={<MoveInCleaningPage />}
      />
      <Route
        path={`${services.path}/${services.children.postRenovationCleaning.path}`}
        element={<PostRenovationCleaningPage />}
      />

      <Route
        path={`${services.path}/${services.children.commercialCleaning.path}`}
        element={<CondoCleaningPage />}
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
