import React from 'react';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import './styles/TitleBackground.scss';

interface TitleBackgroundProperties {
  title: string;
  isShapeShown?: boolean;
}

export const TitleBackground: React.FC<TitleBackgroundProperties> = ({
  title,
  isShapeShown,
}) => {
  return (
    <section
      className={
        isShapeShown
          ? `title-background title-background_triangle-shape`
          : `title-background`
      }
    >
      <Container>
        <h1 className="title-background__title">{title}</h1>
      </Container>
    </section>
  );
};
