import { BookingText } from '../../ui/BookingText';

export const BookingFormHeader = () => {
  return (
    <div className="booking__header">
      <h2 className="booking__main-title">5 Simple Steps</h2>
      <BookingText
        option="description"
        text="Customize your cleaning, view your quote, and book when ready."
      />
      <BookingText
        option="description"
        text="No payment until day of service. 24hr cancellation policy."
      />
    </div>
  );
};
