import React, { useEffect } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BookingField } from '../../modules/BookingForm/types/bookingFieldset';
import {
  CleaningInstructionsEntity,
  LocationInformationEntity,
} from '../../types';
import { useBookingFormData } from '../../hooks/useBookingFormData';

import './styles/SelectForm.scss';

interface SelectFormProps {
  options: string[];
  register: UseFormRegister<BookingField>;
  disabled?: string;
  name: keyof BookingField;
  formFields: BookingField;
}

export const SelectForm: React.FC<SelectFormProps> = ({
  options,
  register,
  disabled,
  name,
  formFields,
}) => {
  const {
    setInstructionsInformation,
    setLocationInformation,
    formData: { cleaningIntstructions, locationInformation },
  } = useBookingFormData();

  const handleDataAddOnChange = () => {
    if (name === 'province') {
      const newLocationInformation: LocationInformationEntity = {
        ...locationInformation,
        [name]: formFields[name],
      };

      setLocationInformation(newLocationInformation);
    } else {
      const newCleaningInstructions: CleaningInstructionsEntity = {
        ...cleaningIntstructions,
        [name]: formFields[name],
      };
      setInstructionsInformation(newCleaningInstructions);
    }
  };

  useEffect(() => {
    if (name === 'province') {
      handleDataAddOnChange();
    }
  }, []);

  return (
    <select
      className="select-form"
      {...register(name, { onChange: handleDataAddOnChange })}
    >
      {disabled && <option disabled>{disabled}</option>}
      {options.map((item) => (
        <option key={item} value={item} className="select-form__option">
          {item}
        </option>
      ))}
    </select>
  );
};
