import { FormMyPrice } from '../components/FormMyPrice';
import { GiftCardFormSection } from '../modules/GiftCardFormSection';
import { GiftCardsPageTitle } from '../modules/GiftCardsPageTitle';
import { OurCleaningServices } from '../modules/OurCleaningServices';
import { Reviews } from '../modules/Reviews';
import { WrapperMyPrice } from '../modules/WrapperMyPrice';
import { MainWrapper } from '../ui/MainWrapper';
import { SvgIconSmileBeam } from '../ui/SvgIcon';

export const GiftCardsPage = () => {
  return (
    <MainWrapper shownBreadCrumbs>
      <GiftCardsPageTitle />
      <GiftCardFormSection />
      <OurCleaningServices />
      <Reviews title="Customer Reviews of Our Cleaning Services" />
      <WrapperMyPrice
        title="Try Our Cleaning Services Today!"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice />}
        icon={<SvgIconSmileBeam />}
      />
    </MainWrapper>
  );
};
