import { TextDescription } from './ui/TextDescription';

import './styles/Text.scss';

export const Text = () => {
  return (
    <div className="gift-cards__text text">
      <div className="text__wrapper">
        <span className="text__subtitle">Give the Gift of a Clean Home</span>
        <h2 className="text__title">Now Offering House Cleaning Gift Cards!</h2>
        <TextDescription />
      </div>
    </div>
  );
};
