import { ThankYou } from '../modules/ThankYou';
import { MainWrapper as Main } from '../ui/MainWrapper';

export const ThankYouPage = () => {
  return (
    <Main shownBreadCrumbs>
      <ThankYou />
    </Main>
  );
};
