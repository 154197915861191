import { paths } from '../../../constants';
import { getNumberSequence } from '../helpers/getNumberSequence';
import { getServiceType } from '../helpers/getServiceType';
import { formSelectConfigType } from '../types/formSelectConfigType';

const bedroomsEnv = 7;
const bathroomsEnv = 4;

const bedRoomsStep = 1;
const bathRoomsStep = 0.5;

const amountBedrooms: number[] = getNumberSequence(bedroomsEnv, bedRoomsStep);
const amountBathrooms: number[] = getNumberSequence(
  bathroomsEnv,
  bathRoomsStep
);

const {
  services: { children },
} = paths;
const servicesArray = Object.entries(children).map((service) => service[1]);

export const formSelectConfig: formSelectConfigType[] = [
  {
    name: 'Type of Cleaning',
    value: 'serviceType',
    option: getServiceType(servicesArray),
  },
  {
    name: 'No. of Bedrooms',
    value: 'bedrooms',
    option: amountBedrooms,
  },
  {
    name: 'No. of Washrooms',
    value: 'washrooms',
    option: amountBathrooms,
  },
];
