import { paths } from '../../../../constants';
import { MenuItem } from '../MenuItem/MenuItem';

import './styles/DropDownMenu.styles.scss';

export const DropdownMenu = () => {
  const {
    services: { children },
  } = paths;

  return (
    <div className="dropdown">
      <ul className="dropdown__list">
        {Object.entries(children).map((route) => {
          return (
            <MenuItem
              key={route[0]}
              route={route[1].path}
              type="dropdown"
              isCleaningService
            />
          );
        })}
      </ul>
    </div>
  );
};
