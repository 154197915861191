import React, { useEffect, useState } from 'react';
import { useBookingFormData } from '../../../../hooks/useBookingFormData';
import { discounts } from '../../../../constants';
import { discountsType } from '../../../../types/discountsType';
import { formatDateTime } from './helpers/formatDateTime';
import { CleaningInformationAdditionsEntity } from '../../../../types';
import { getSubTotal } from './helpers/getSubTotal';
import { BookingSummaryPrice } from './components/BookingSummaryPrice';
import { provinces } from '../../../../constants/provinces';
import { BookingField } from '../../types/bookingFieldset';

import './styles/BookingSummary.scss';

interface BookingSummaryProps {
  formFields: BookingField;
}

interface finalPriceType {
  subTotal: number;
  total: number;
  salesTax: number;
  recurring: number;
}

export const BookingSummary: React.FC<BookingSummaryProps> = ({
  formFields,
}) => {
  const {
    formData: { cleaningInformation, dateInformation },
    setTotalPrice,
    setRecurringPrice,
  } = useBookingFormData();
  const [frequency, setFrequency] = useState<discountsType>();
  const [dateTime, setDateTime] = useState<string>();
  const [parameters, setParameters] =
    useState<CleaningInformationAdditionsEntity[]>();
  const { province: currentProvince, frequencyId } = formFields;
  const taxPercent = provinces.find(
    (province) => province.name === currentProvince
  )?.tax;

  const [finalPrice, setFinalPrice] = useState<finalPriceType>({
    subTotal: 0,
    total: 0,
    salesTax: 0,
    recurring: 0,
  });

  useEffect(() => {
    if (dateInformation?.date) {
      setDateTime(formatDateTime(dateInformation.date));
    }
  }, [dateInformation?.date]);

  useEffect(() => {
    if (frequencyId) {
      setFrequency(discounts.find((item) => item.id === Number(frequencyId)));
    }
  }, [frequencyId]);

  useEffect(() => {
    if (
      cleaningInformation &&
      cleaningInformation.cleaningExtras &&
      cleaningInformation.cleaningParameters
    ) {
      setParameters([
        ...cleaningInformation.cleaningParameters,
        ...cleaningInformation.cleaningExtras,
      ]);
    }
  }, [cleaningInformation]);

  useEffect(() => {
    let subTotal = 0;
    let discount = 0;
    let total = 0;

    if (parameters) {
      subTotal = getSubTotal(parameters, cleaningInformation?.price as number);
    } else {
      subTotal = cleaningInformation?.price as number;
    }
    const taxAmount = (Number(taxPercent) / 100) * subTotal;
    total = taxAmount + subTotal;
    if (frequency) {
      discount = total * (frequency.value / 100);
    }
    const recurring = total - discount;

    setFinalPrice((prevPrice) => ({
      ...prevPrice,
      subTotal,
      salesTax: taxAmount,
      total,
      recurring,
    }));
    setTotalPrice(total);
    if (recurring !== 0) {
      setRecurringPrice(total);
    }
  }, [parameters, frequency]);

  return (
    <section className="booking-summary">
      <div className="booking-summary__title">
        <h2>Booking Summary</h2>
      </div>
      <div className="booking-summary__main-information">
        <div className="booking-summary__type-cleaning">
          {cleaningInformation?.id !== 0 ? (
            <div className="booking-summary__type-cleaning__item booking-summary-text">
              <p>{cleaningInformation?.name}</p>
              <p>
                $
                {cleaningInformation?.price &&
                  cleaningInformation.price.toFixed(2)}
              </p>
            </div>
          ) : (
            <p>Choose type of cleaning...</p>
          )}
        </div>
        <div className="booking-summary__parameters">
          <ul className="booking-summary__parameters__wrapper">
            {parameters?.map((parameter) => (
              <li
                className="booking-summary__parameters__item booking-summary-text"
                key={parameter.id}
              >
                <p className="booking-summary__parameters__item_name">
                  {parameter.name}
                </p>
                <p className="booking-summary__parameters__item_price">
                  ${parameter.totalPrice && parameter.totalPrice.toFixed(2)}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="booking-summary__date booking-summary-text">
          {dateTime ? <p>{dateTime}</p> : <p>Choose service date...</p>}
        </div>
        <div className="booking-summary__frequency">
          {frequency ? <p>{frequency.name}</p> : <p>One time</p>}
        </div>
      </div>
      {cleaningInformation?.id !== 0 && (
        <BookingSummaryPrice
          subTotal={finalPrice.subTotal}
          salesTax={finalPrice.salesTax}
          frequency={frequency}
          total={Number(finalPrice.total)}
          recurring={finalPrice.recurring}
        />
      )}
    </section>
  );
};
