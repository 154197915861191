import { ContainerComponent } from '../../ui/ContainerComponent';
import { ServiceExclusionsItem } from './components/ServiceExclusionsItem';
import { ServiceExclusionsData } from './constants/ServiceExclusionsData';

import './styles/ServiceExclusions.scss';

export const ServiceExclusions = () => {
  return (
    <section className="service-exclusions">
      <ContainerComponent>
        <div className="service-exclusions__wrapper">
          <div className="service-exclusions__header">
            <h2 className="service-exclusions__title">Service Exclusions</h2>
            <p className="service-exclusions__desc">
              The following services are{' '}
              <span className="service-exclusions__desc_bold">
                not provided
              </span>{' '}
              by Crystal Clear
            </p>
          </div>
          <div className="service-exclusions__container">
            {ServiceExclusionsData.map((item) => (
              <ServiceExclusionsItem key={item.text} item={item} />
            ))}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
