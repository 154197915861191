import React from 'react';
import { InclusionDataEntity } from './types/InclusionDataEntity';
import { getAltTextForImage } from '../../helpers';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';

import './styles/ServiceInclusions.scss';

interface ServiceInclusionsProperties {
  inclusionsData: InclusionDataEntity[];
}

export const ServiceInclusions: React.FC<ServiceInclusionsProperties> = ({
  inclusionsData,
}) => {
  return (
    <section className="service-inclusions">
      <Container>
        <div className="service-inclusions__wrapper">
          <h2 className="service-inclusions__title">Service Inclusions</h2>
          <div className="service-inclusions__cards-wrapper">
            {inclusionsData.map((inclusion) => {
              const altText = getAltTextForImage(inclusion.imageSource);

              return (
                <div key={inclusion.text} className="service-inclusions__card">
                  <img
                    className="service-inclusions__card-image"
                    src={inclusion.imageSource}
                    alt={altText}
                  />
                  <p className="service-inclusions__card-text">
                    {inclusion.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};
