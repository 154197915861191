/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { BookingField } from '../../../types/bookingFieldset';
import { BookingCleaningServiceParameter } from './BokingCleaningServiceParameter';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';
import {
  AdditionalBookingEntity,
  CleaningService,
  IntermediatePriceData,
  PricingParametersEntity,
  RangesEntity,
} from '../../../../../types';

interface BookingCleaningServiceParametersProperties {
  cleaningServices: CleaningService[];
  register: UseFormRegister<BookingField>;
  setValue: UseFormSetValue<BookingField>;
}

export const BookingCleaningParameters: React.FC<
  BookingCleaningServiceParametersProperties
> = ({ register, setValue, cleaningServices }) => {
  const { formData } = useBookingFormData();
  const [pricingParameters, setPricingParameters] = useState<
    PricingParametersEntity[]
  >([]);
  const activeService = useMemo(() => {
    return (
      cleaningServices.find(
        (currentService) =>
          formData.cleaningInformation?.id === currentService.id
      ) || null
    );
  }, [formData.cleaningInformation?.id, cleaningServices]);

  useEffect(() => {
    if (activeService && activeService.pricingParameters) {
      const formattedPricingParameters: PricingParametersEntity[] = [];
      const bathroom = activeService.pricingParameters.find(
        (parameter) => parameter.name === 'Bathroom'
      );
      const bathroomHalf = activeService.pricingParameters.find(
        (parameter) => parameter.name === 'Half Baths'
      );
      const washroomEntity: PricingParametersEntity = {
        id: bathroom?.id as number,
        name: 'Washroom',
        quantityMaximum: bathroom?.quantityMaximum as number,
        quantityMinimum: bathroom?.quantityMinimum as number,
      };
      const washroomHalfEntity: PricingParametersEntity = {
        id: bathroomHalf?.id as number,
        name: 'Half Wash',
        quantityMaximum: bathroomHalf?.quantityMaximum as number,
        quantityMinimum: bathroomHalf?.quantityMinimum as number,
      };

      const createWashs = (wash: PricingParametersEntity) => {
        const arrayOfWashrooms: RangesEntity[] = [];

        for (let i = wash.quantityMinimum; i <= wash.quantityMaximum; i++) {
          const label =
            i === 1
              ? `${i} ${wash.name}`
              : i === 0
                ? `No ${wash.name}s`
                : `${i} ${wash.name}s`;

          const washr = {
            label,
            price: (bathroom?.price as number) * i,
            quantity: i,
            duration: null,
          };

          arrayOfWashrooms.push(washr);
        }

        return arrayOfWashrooms;
      };

      const createHalfWashs = (wash: PricingParametersEntity) => {
        const arrayOfWashrooms: RangesEntity[] = [];

        for (let i = wash.quantityMinimum; i <= wash.quantityMaximum; i++) {
          const label =
            i === 1
              ? `${i} ${wash.name}`
              : i === 0
                ? `No ${wash.name}s`
                : `${i} ${wash.name}s`;

          const washr = {
            label,
            price: (bathroomHalf?.price as number) * i,
            quantity: i,
            duration: null,
          };

          arrayOfWashrooms.push(washr);
        }

        return arrayOfWashrooms;
      };

      washroomEntity.ranges = createWashs(washroomEntity);
      washroomHalfEntity.ranges = createHalfWashs(washroomHalfEntity);

      formattedPricingParameters.push(washroomEntity);
      formattedPricingParameters.push(washroomHalfEntity);

      setPricingParameters([
        ...activeService.pricingParameters,
        ...formattedPricingParameters,
      ]);
    }

    if (activeService && activeService.hourly) {
      const formattedPricingParameters: PricingParametersEntity[] = [];
      const {
        quantityMinimum,
        quantityMaximum,
        minutesMaximum,
        minutesMinimum,
      } = activeService.hourly;
      const { price } = activeService;
      const cleanerEntity: PricingParametersEntity = {
        id: 10001,
        name: 'Cleaner',
        quantityMaximum,
        quantityMinimum,
      };
      const hourEntity: PricingParametersEntity = {
        id: 10002,
        name: 'Hours',
        quantityMaximum: minutesMaximum / 60,
        quantityMinimum: minutesMinimum / 60,
      };

      const createCleaner = (clea: PricingParametersEntity) => {
        const arrayOfCleaners: RangesEntity[] = [];

        for (let i = clea.quantityMinimum; i <= clea.quantityMaximum; i++) {
          const label = i === 1 ? `${i} ${clea.name}` : `${i} ${clea.name}s`;
          const cleaner = {
            label,
            price: price * i,
            quantity: i,
            duration: null,
          };

          arrayOfCleaners.push(cleaner);
        }

        return arrayOfCleaners;
      };

      const createHours = (hour: PricingParametersEntity) => {
        const arrayOfHours: RangesEntity[] = [];

        for (let i = hour.quantityMinimum; i <= hour.quantityMaximum; i++) {
          const label = `${i} ${hour.name}`;

          const hours = {
            label,
            price: i,
            quantity: i,
            duration: null,
          };

          arrayOfHours.push(hours);
        }

        return arrayOfHours;
      };

      cleanerEntity.ranges = createCleaner(cleanerEntity);
      hourEntity.ranges = createHours(hourEntity);

      formattedPricingParameters.push(cleanerEntity);
      formattedPricingParameters.push(hourEntity);

      setPricingParameters(formattedPricingParameters);
    }

    if (
      activeService &&
      !activeService.hourly &&
      !activeService.pricingParameters
    ) {
      setPricingParameters([]);
    }
  }, [activeService]);

  useEffect(() => {
    if (activeService && activeService.hourly) {
      const priceData: IntermediatePriceData = {
        parameters: [],
      };

      pricingParameters.forEach((parameter) => {
        const { ranges, id } = parameter;

        if (ranges) {
          const { label, price, quantity } = ranges[0] as RangesEntity;
          const defaultHour: AdditionalBookingEntity = {
            id,
            name: label,
            quantity,
            totalPrice: price,
          };
          priceData.parameters?.push(defaultHour);
        }
      });
    }
  }, [pricingParameters]);

  return (
    <div className="fieldset__input-wrapper fieldset__input-wrapper_centered">
      {Object.values(pricingParameters).map((parameter) => {
        const { name, ranges, id } = parameter;
        const nameNoSpaces = name.replaceAll(' ', '');
        const nameCamelCase =
          nameNoSpaces[0].toLowerCase() + nameNoSpaces.slice(1);

        return (
          <Fragment key={name}>
            {ranges && (
              <BookingCleaningServiceParameter
                parameterNameNormalized={nameCamelCase}
                parameterID={id}
                parameterRanges={ranges}
                register={register}
                setValue={setValue}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
