import React from 'react';
import { BurgerButton } from '../BurgerButton/BurgerButton';
import { breakpoints } from '../../../../constants';
import { PhoneButton } from '../PhoneButton/PhoneButton';
import { HeaderButton } from '../HeaderButton/HeaderButton';
import { useWidthWindow } from '../../../../hooks/useWidthWindows';

import './styles/HeaderButtons.styles.scss';

interface BurgerButtonProps {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderButtons: React.FC<BurgerButtonProps> = ({
  isSideMenuOpen,
  setIsSideMenuOpen,
}) => {
  const { windowWidth } = useWidthWindow();

  return (
    <div className="buttons">
      <HeaderButton text="book" />
      {windowWidth >= breakpoints.tablet && <HeaderButton text="login" />}
      <PhoneButton />

      <BurgerButton
        isSideMenuOpen={isSideMenuOpen}
        setIsSideMenuOpen={setIsSideMenuOpen}
      />
    </div>
  );
};
