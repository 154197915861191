import React, { useEffect, useState } from 'react';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { OurCleaningServicesItem } from './components/OurCleaningServicesItem/OurCleaningServicesItem';
import { OurCleaningServicesData } from './constants/OurCleaningServicesData';
import { breakpoints } from '../../constants';
import { OurCleaningServicesDataType } from './types/OurCleaningServicesDataType';
import { itemMobilNoDisplay } from './constants/itemMobilNoDisplay';

import './styles/OurCleaningServices.scss';

export const OurCleaningServices: React.FC = () => {
  const { windowWidth } = useWidthWindow();
  const [itemsCleaningServices, setItemsCleaningServices] = useState<
    OurCleaningServicesDataType[]
  >([]);

  useEffect(() => {
    if (windowWidth <= breakpoints.tablet) {
      const mobileData = OurCleaningServicesData.filter(
        (item) => item.title !== itemMobilNoDisplay
      );
      setItemsCleaningServices(mobileData);
    } else {
      setItemsCleaningServices(OurCleaningServicesData);
    }
  }, [windowWidth]);

  return (
    <section className="our-cleaning-services">
      <ContainerComponent>
        <div className="our-cleaning-services__wrapper">
          <h2 className="our-cleaning-services__title">
            Our Cleaning Services
          </h2>
          <div className="our-cleaning-services__container">
            {itemsCleaningServices.map((item) => (
              <OurCleaningServicesItem key={item.title} item={item} />
            ))}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
