import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { GiftCardsPageTitleSwiper } from './components/GiftCardsPageTitleSwiper';
import { paths } from '../../constants';

import './styles/GiftCardsPageTitle.scss';

export const GiftCardsPageTitle = () => {
  return (
    <section className="gift-cards-page-title">
      <ContainerComponent>
        <div className="gift-cards-page-title__wrapper">
          <div className="gift-cards-page-title__text">
            <h1 className="gift-cards-page-title__title">GIFT CARDS</h1>
            <h3 className="gift-cards-page-title__sub-title">
              Give the Gift of a Clean Home!
            </h3>
            <p className="gift-cards-page-title__desc">
              Looking to gift a friend, family member, or employee? Try
              something different and send a gift that lasts! Whether it’s a
              birthday celebration, a gift for your parents, or just a simple
              <span className="gift-cards-page-title__desc_italic">
                “thank you”
              </span>{' '}
              to someone special, a house cleaning gift will go a long way in
              making a lasting and unique impression!
            </p>
            <p className="gift-cards-page-title__desc">
              Gift cards are delivered directly to the recipient and can apply
              to any of our cleaning services! Once you{' '}
              <span className="gift-cards-page-title__desc_bold">
                purchase a gift card below,
              </span>{' '}
              a code gets sent to the recipient’s email. The recipient will then
              input the code in the discount field of our{' '}
              <Link
                className="gift-cards-page-title__desc_link"
                to={paths.book}
              >
                booking page
              </Link>
              , and voilà, the gift amount is applied! 🎁
            </p>
          </div>
          <GiftCardsPageTitleSwiper />
        </div>
      </ContainerComponent>
    </section>
  );
};
