import { companyInfo } from '../constants';
import { AreaCleaning } from '../modules/AreaCleaning';
import { ContactSection } from '../modules/ContactSection';
import { IFrameEtobicoke } from '../modules/ContactSection/components/IFrame';
import { GiftCards } from '../modules/GiftCards';
import { OurCleaningServices } from '../modules/OurCleaningServices';
import { SpecificArea } from '../types';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { MapWrapper } from '../ui/MapWrapper';
import { EtobicokeText } from './constants/Etobicoke';

import './styles/page.scss';

export const EtobicokePage = () => {
  return (
    <div>
      <Main shownBreadCrumbs>
        <h1 className="page__title">Etobicoke</h1>
        <OurCleaningServices />
        <AreaCleaning
          city={EtobicokeText.city}
          description={EtobicokeText.description}
          map={<MapWrapper specificArea={SpecificArea.Etobicoke} />}
        />
        <ContactSection
          city="Etobicoke"
          address={companyInfo.offices.etobicoke}
          hours={companyInfo.hours}
          officesMap={companyInfo.officesMap.etobicoke}
          frame={<IFrameEtobicoke />}
        />
        <GiftCards />
      </Main>
    </div>
  );
};
