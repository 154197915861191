import { Link } from 'react-router-dom';
import './styles/NotFoundModule.styles.scss';

export const NotFoundModule = () => {
  return (
    <div className="not-found__wrapper">
      <h1>Page Not Found</h1>
      <Link to="/">Back to main page</Link>
    </div>
  );
};
