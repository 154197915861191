import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { BeforeAfterSwiper } from './components/BeforeAfterSwiper';

import './styles/BeforeAfterSection.scss';

export const BeforeAfterSection = () => {
  return (
    <section className="before-after">
      <ContainerComponent>
        <div className="before-after__container">
          <h2 className="before-after__title">
            <Link to="/">Before and After Scenes of our Work!</Link>
          </h2>
          <BeforeAfterSwiper />
        </div>
      </ContainerComponent>
    </section>
  );
};
