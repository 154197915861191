import { HelpfulLinks } from '../types';
import { paths } from './paths';

export const helpfulLinks: HelpfulLinks[] = [
  {
    name: 'About Us',
    path: 'about-us',
  },
  {
    name: 'Areas we Serve',
    path: 'locations',
  },
  {
    name: 'Work with us',
    path: 'apply',
  },
  {
    name: 'Reviews',
    path: 'reviews',
  },
  {
    name: 'Contact Us',
    path: 'contact-us',
  },
  {
    name: 'Blog',
    path: 'cleaning-blog',
  },
  {
    name: 'Customer Login',
    path: 'https://crystalclear.launch27.com/login',
  },
  {
    name: 'Book Now',
    path: paths.book,
  },
  {
    name: 'Gift Cards',
    path: 'gift-cards',
  },
  {
    name: 'Partnerships',
    path: 'our-partners',
  },
  {
    name: 'Scholarships',
    path: 'scholarship',
  },
];
