import { useLocation } from 'react-router-dom';
import { CustomBreadcrumbsItem } from './components/CustomBreadcrumbsItem';

import './styles/CustomBreadcrumbs.scss';

export const CustomBreadcrumbs = () => {
  const { pathname } = useLocation();

  return (
    <div className="custom-breadcrumbs">
      {pathname !== '/' && <CustomBreadcrumbsItem path={pathname} />}
    </div>
  );
};
