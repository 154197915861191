export const subjectsFormData = [
  'Select one of the following inquires',
  'I`d like to inquire about pricing',
  'I`d like to book a cleaning service',
  'I`m a customer and need support',
  'I`d like to speak with someone',
  'I`m looking for employment',
  'I have a complaint',
  'I have an idea for Hellamaid',
  'Other',
];
