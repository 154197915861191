import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { areas, helpfulLinks, companyInfo, paths } from '../../constants';
import { getAreaURL, getFormattedPhoneNumber } from '../../helpers';
import {
  SvgIconFacebook,
  SvgIconLinkedin,
  SvgIconInstagram,
  SvgIconTikTok,
  SvgIconYouTube,
} from '../../ui/SvgIcon';
import './styles/FooterModule.scss';
import logo from '../../assets/logo120х120.png';
import ccs from '../../assets/footer/CCS.png';
import livingWage from '../../assets/footer/living_wage.png';
import lw from '../../assets/footer/LW.png';
import olwn from '../../assets/footer/OLWN.png';

export const Footer: React.FC = () => {
  const {
    services: { children, path: servicesPath },
  } = paths;
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);

  return (
    <footer className="footer">
      <ContainerComponent>
        <div className="footer__nav">
          <div className="nav__areas">
            <NavLink className="nav__areas__title" to={paths.areas.path}>
              Areas we serve
            </NavLink>
            {areas.map((province) => (
              <div key={province.name} className="nav__area">
                <h3 className="nav__area__province">{province.name}</h3>
                <div className="nav__area__wrapper">
                  {province.cities.map((city) => {
                    const URL = getAreaURL(city.name);

                    return (
                      <div key={city.name}>
                        <NavLink className="nav__area__city" to={URL}>
                          {city.name}
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="nav__services">
            <h2 className="nav__services__title">Services</h2>
            <div className="nav__services__wrapper">
              {Object.entries(children).map((service) => {
                const serviceData = service[1];

                return (
                  <NavLink
                    className="nav__services__item"
                    key={serviceData.name}
                    to={`${servicesPath}/${serviceData.path}`}
                  >
                    {serviceData.name}
                  </NavLink>
                );
              })}
            </div>
          </div>
          <div className="nav__help-links">
            <h2 className="nav__help-links__title">Helpful Links</h2>
            <div className="nav__help-links__wrapper">
              {helpfulLinks.map((link) => (
                <NavLink
                  className="nav__help-links__item"
                  key={link.name}
                  to={link.path}
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="nav__touch">
            <h2 className="nav__touch__title">Get in Touch</h2>
            <button className="nav__touch_call">
              <a href={`tel:${formattedPhone}`}>{companyInfo.phoneNumber}</a>
            </button>
            <button className="nav__touch_email">
              <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
            </button>
            <Link className="nav__touch_book" to={paths.book}>
              Book Online
            </Link>
            <Link className="nav__touch_cards" to={paths.giftCards}>
              Gift Cards 🎁
            </Link>
            <Link className="nav__touch_review" to={paths.reviews}>
              Our Reviews
            </Link>
          </div>
        </div>
        <div className="footer__container">
          <div className="logo">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="mission-statements">
            <p className="statement">1. Delighting Homeowners </p>
            <p className="statement">2. Supporting Communities</p>
            <p className="statement">3. Empowering Cleaners</p>
          </div>
          <div className="social-links">
            <a href={companyInfo.socialLinks.instagram}>
              <SvgIconInstagram />
            </a>
            <a href={companyInfo.socialLinks.linkedin}>
              <SvgIconLinkedin />
            </a>
            <a href={companyInfo.socialLinks.facebook}>
              <SvgIconFacebook />
            </a>
            <a href={companyInfo.socialLinks.youtube}>
              <SvgIconYouTube />
            </a>
            <a href={companyInfo.socialLinks.tiktok}>
              <SvgIconTikTok />
            </a>
          </div>
          <div className="partners-links">
            <a href="/">
              <img alt="partners-links" src={olwn} />
            </a>
            <a href="/">
              <img alt="partners-links" src={livingWage} />
            </a>
            <a href="/">
              <img alt="partners-links" src={ccs} />
            </a>
            <a href="/">
              <img alt="partners-links" src={lw} />
            </a>
          </div>
          <div className="footer-signature">
            <p className="footer-signature__item">
              Copyright © 2024 Crystal Clear Inc - All Rights Reserved -&nbsp;
              <NavLink className="footer-signature__item" to="/">
                Terms and Conditions&nbsp;
              </NavLink>
              -&nbsp;
              <NavLink className="footer-signature__item" to="/">
                Privacy Policy&nbsp;
              </NavLink>
              - Proudly Canadian&nbsp;
              <span>🇨🇦</span>
            </p>
          </div>
        </div>
      </ContainerComponent>
    </footer>
  );
};
