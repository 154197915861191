import { ToastContainer } from 'react-toastify';
import { HeaderModule } from './modules/HeaderModule';
import { Footer } from './modules/Footer';
import { RoutesApp } from './router';
import { ScrollToTop } from './helpers';

import 'swiper/scss';
import 'swiper/scss/scrollbar';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <ScrollToTop />
      <HeaderModule />
      <RoutesApp />
      <Footer />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
    </>
  );
};

export default App;
