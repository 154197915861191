import React from 'react';
import { ValuesCleaningType } from '../type/ValuesCleaningType';

interface ValuesCleaningTextProps {
  text: ValuesCleaningType;
}

export const ValuesCleaningText: React.FC<ValuesCleaningTextProps> = ({
  text,
}) => {
  const { title, desc } = text;
  return (
    <div className="values-cleaning-text">
      <h2 className="values-cleaning-text__title">{title}</h2>
      <p className="values-cleaning-text__desc">{desc}</p>
    </div>
  );
};
