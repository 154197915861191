import React from 'react';

import { BurgerIcon } from './ui/BurgerIcon';
import { BurgerIconType } from '../../types/BurgerIconType';

import './styles/BurgerButton.styles.scss';

interface BurgerButtonProps {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BurgerButton: React.FC<BurgerButtonProps> = ({
  isSideMenuOpen,
  setIsSideMenuOpen,
}) => {
  const iconType: BurgerIconType = isSideMenuOpen ? 'close' : 'burger';

  return (
    <button
      className="burger"
      onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
    >
      <BurgerIcon type={iconType} />
    </button>
  );
};
