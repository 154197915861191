import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { CleaningZonesItem } from './components/CleaningZonesItem';
import { paths } from '../../constants';
import { CleaningZonesData } from './constants/CleaningZonesData';

import './styles/CleaningZones.scss';

export const CleaningZones = () => {
  return (
    <section className="cleaning-zones">
      <ContainerComponent>
        <div className="cleaning-zones__wrapper">
          <div className="cleaning-zones__container">
            {CleaningZonesData.map((item) => {
              return <CleaningZonesItem key={item.title} item={item} />;
            })}
          </div>
          <div className="cleaning-zones__desc">
            <p className="cleaning-zones__desc__text">
              Standard cleaning includes all checked items, deep cleaning
              includes all checked items along with deep items, move in cleaning
              involves all items.
            </p>
            <p className="cleaning-zones__desc__text">
              Need specific or fewer cleaning tasks? Choose our hourly service
              option at Step 2 of our{' '}
              <Link className="cleaning-zones__desc__link" to={paths.book}>
                booking form
              </Link>{' '}
              and specify your needs in the notes section.
            </p>
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
