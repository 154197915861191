export const paths = {
  root: '/',
  book: '/book-online',
  thankYou: '/thank-you',
  reviews: '/reviews',
  giftCards: '/gift-cards',
  contactUs: '/contact-us',
  areas: {
    path: '/areas',
    children: {
      toronto: 'cleaning-services-toronto',
      etobicoke: 'cleaning-services-etobicoke',
      northYork: 'cleaning-services-north-york',
      brampton: 'cleaning-services-brampton',
      mississauga: 'cleaning-services-mississauga',
      ajax: 'cleaning-services-ajax',
      milton: 'cleaning-services-milton',
    },
  },
  services: {
    path: '/services',
    children: {
      checklist: {
        path: 'checklist',
        name: 'Our Cleaning Checklist',
      },
      cleaningServices: {
        path: 'cleaning-services',
        name: 'House Cleaning',
      },
      apartmentCleaning: {
        path: 'condo-and-apartment-cleaning-services',
        name: 'Condo and Apartment Cleaning',
      },
      commercialCleaning: {
        path: 'commercial-office-cleaning-services',
        name: 'Commercial Cleaning',
      },
      deepCleaning: {
        path: 'deep-cleaning-services',
        name: 'Deep cleaning Services',
      },
      moveInCleaning: {
        path: 'move-in-move-out-cleaning-services',
        name: 'Move in Move out cleaning',
      },
      ecoCleaning: {
        path: 'green-and-eco-friendly-cleaning-services',
        name: 'Green and Eco-Friendly Cleaning',
      },
      postRenovationCleaning: {
        path: 'post-renovation-cleaning-services',
        name: 'Post Renovation Cleaning',
      },
      airbnbCleaning: {
        path: 'airbnb-cleaning-services',
        name: 'AirBnB Cleaning',
      },
      seniorsCleaning: {
        path: 'cleaning-services-for-seniors',
        name: 'Cleaning Services for Seniors',
      },
      springCleaning: {
        path: 'spring-cleaning-services',
        name: 'Spring Cleaning',
      },
    },
  },
};
