import React, { useEffect, useState } from 'react';
import './styles/MainWrapper.scss';

interface MainWrapperProps {
  children: React.ReactNode;
  shownBreadCrumbs?: boolean;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({
  children,
  shownBreadCrumbs,
}) => {
  const [isBreadCrumbsShown, setIsBreadCrumbsShown] = useState<boolean>();
  useEffect(() => {
    setIsBreadCrumbsShown(shownBreadCrumbs);
  }, []);

  return (
    <main
      className={
        isBreadCrumbsShown
          ? 'page-wrapper page-wrapper__breadcrumbs'
          : 'page-wrapper'
      }
    >
      {children}
    </main>
  );
};
