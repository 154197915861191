import React from 'react';
import { ButtonDataType } from '../../types/buttonDataType';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { Button } from './ui/Button';

import './styles/ActionButtons.scss';

interface ActionButtonsProps {
  data: ButtonDataType[];
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ data }) => {
  return (
    <div className="action-buttons">
      <Container>
        <div className="action-buttons__wrapper">
          {data.map((buttonData) => {
            return <Button key={buttonData.text} data={buttonData} />;
          })}
        </div>
      </Container>
    </div>
  );
};
