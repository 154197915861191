import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { EmpoweredCleanersSection } from './components/EmpoweredCleanersSection/EmpoweredCleanersSection';
import { RedefiningCleaningSection } from './components/RedefiningCleaningSection/RedefiningCleaningSection';
import { TeamSection } from './components/TeamSection/TeamSection';
import { FriendlyProfessionals } from './components/FriendlyProfessionals/FriendlyProfessionals';
import './styles/ThreeSectionsOfStuff.scss';

interface OurStuffProperties {
  hasOnlyOneSection?: boolean;
}

export const OurStuff: React.FC<OurStuffProperties> = ({
  hasOnlyOneSection = false,
}) => {
  return (
    <section className="three-sections-of-stuff">
      <ContainerComponent>
        <div className="three-sections-of-stuff__container">
          {hasOnlyOneSection ? (
            <FriendlyProfessionals />
          ) : (
            <>
              <EmpoweredCleanersSection />
              <RedefiningCleaningSection />
              <TeamSection />
            </>
          )}
        </div>
      </ContainerComponent>
    </section>
  );
};
