import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BookingField } from '../../../../types/bookingFieldset';
import { discountsType } from '../../../../../../types/discountsType';

import './styles/ButtonOften.scss';

interface ButtonOftenProps {
  discounts: discountsType[];
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
  handleDataAddOnChange: () => void;
}

export const ButtonOften: React.FC<ButtonOftenProps> = ({
  discounts,
  register,
  formFields,
  handleDataAddOnChange,
}) => {
  const { frequencyId } = formFields;

  return (
    <div className="where-when__often__actions">
      {discounts.map((item) => {
        return (
          <label
            className={
              Number(frequencyId) === item.id
                ? 'button-often active-button'
                : 'button-often'
            }
            htmlFor={String(item.id)}
            key={item.id}
          >
            <input
              type="radio"
              value={item.id}
              {...register('frequencyId', {
                onChange: handleDataAddOnChange,
              })}
              id={String(item.id)}
              className={
                frequencyId === item.id
                  ? 'button-often active-button'
                  : 'button-often'
              }
            />
            {item.name}
          </label>
        );
      })}
    </div>
  );
};
