import { Areas } from '../types';

export const areas: Areas[] = [
  {
    name: 'Ontario',
    cities: [
      {
        name: 'Toronto',
      },
      {
        name: 'Etobicoke',
      },
      {
        name: 'North York',
      },
      {
        name: 'Mississauga',
      },
      {
        name: 'Brampton',
      },
      {
        name: 'Caledon',
      },
      {
        name: 'Vaughan',
      },
      {
        name: 'Richmond Hill',
      },
      {
        name: 'Markham',
      },
      {
        name: 'Aurura',
      },
      {
        name: 'Newmarket',
      },
      {
        name: 'King',
      },
      {
        name: 'East Gwillimbury',
      },
      {
        name: 'Stouffville',
      },
      {
        name: 'Georgina',
      },
      {
        name: 'Pickering',
      },
      {
        name: 'Ajax',
      },
      {
        name: 'Whitby',
      },
      {
        name: 'Oshawa',
      },
      {
        name: 'Uxbridge',
      },
      {
        name: 'Oakville',
      },
      {
        name: 'Burlington',
      },
      {
        name: 'Milton',
      },
      {
        name: 'Halton Hills',
      },
    ],
  },
];
