export type MainArea = 'toronto';

// eslint-disable-next-line no-shadow
export enum SpecificArea {
  Toronto = 'Toronto',
  NorthYork = 'North York',
  York = 'York',
  EastYork = 'East York',
  Scarborough = 'Scarborough',
  Etobicoke = 'Etobicoke',
}
