import { HeaderButton } from '../HeaderButton/HeaderButton';
import { HeaderMenu } from '../HeaderMenu/HeaderMenu';
import { breakpoints } from '../../../../constants';
import { useWidthWindow } from '../../../../hooks/useWidthWindows';

import './styles/HeaderSideMenu.styles.scss';

export const HeaderSideMenu = () => {
  const { windowWidth } = useWidthWindow();

  return (
    <aside className="aside">
      <div className="aside__wrapper">
        <HeaderMenu />
        {windowWidth < breakpoints.tablet && <HeaderButton text="login" />}
      </div>
    </aside>
  );
};
