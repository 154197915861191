import { breakpoints } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { FormMyPrice } from '../../components/FormMyPrice';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { PageTitleBackground } from '../../modules/PageTitleBackground';
import { RecurringCleaning } from '../../modules/RecurringCleaning';
import { Reviews } from '../../modules/Reviews';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { MainWrapper } from '../../ui/MainWrapper';
import { SvgIconSmileBeam } from '../../ui/SvgIcon';
import { MainSwiper } from '../../components/MainSwiper/MainSwiper';
import { AcrossCanada } from '../../modules/AcrossCanada';

export const ChecklistPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <MainWrapper shownBreadCrumbs>
      <PageTitleBackground title="Our Cleaning Checklist" />
      <CleaningZones />
      <ServiceExclusions />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <MainSwiper />
      <ActionButtons data={twoButtonsData} />
      <AcrossCanada />
      <RecurringCleaning />
      <InSixtySeconds />
      <Reviews title="Customer Reviews of Our Cleaning Services" />
      <WrapperMyPrice
        title="Try Our Cleaning Services Today!"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized />}
        icon={<SvgIconSmileBeam />}
      />
    </MainWrapper>
  );
};
