import { twoButtonsData } from '../constants/buttonSectionData';
import { ActionButtons } from '../modules/ActionButtons';
import { FormMyPrice } from '../components/FormMyPrice';
import { OurCleaningServices } from '../modules/OurCleaningServices';
import { WrapperMyPrice } from '../modules/WrapperMyPrice';
import { MainWrapper } from '../ui/MainWrapper';
import { SvgIconLeaf } from '../ui/SvgIcon';
import { PageTitleBackground } from '../modules/PageTitleBackground';
import { AreasPageAreas } from '../modules/AreasPageAreas';

export const AreasPage = () => {
  return (
    <MainWrapper shownBreadCrumbs>
      <PageTitleBackground title="areas we serve" />
      <AreasPageAreas />
      <OurCleaningServices />
      <ActionButtons data={twoButtonsData} />
      <WrapperMyPrice
        title="Empowering Cleaners, Elevating Homes"
        desc="One Canadian City at a Time"
        form={<FormMyPrice />}
        icon={<SvgIconLeaf />}
      />
    </MainWrapper>
  );
};
