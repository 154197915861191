import { FormMyPrice } from '../../components/FormMyPrice';
import { breakpoints } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { HeroSection } from '../../modules/HeroSection';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { OurStuff } from '../../modules/ThreeSectionsOfStuff';
import { ProfessionalCleaning } from '../../modules/ProfessionalCleaning';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { SvgIconSmile } from '../../ui/SvgIcon';
import homeCleaningPageImage from '../../assets/heroSection/home-cleaning.jpg';
import { ValuesCleaning } from '../../modules/ValuesCleaning';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';

export const SpringCleaningPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main shownBreadCrumbs>
      <HeroSection
        title="Spring Cleaning Services"
        subtitle="Canada’s #1 Choice for Spring Cleaning Services Life’s too short for cleaning, leave the mess to us!"
        imageSource={homeCleaningPageImage}
      />
      <ProfessionalCleaning
        title="Professional Spring Cleaning Services"
        desc="Spring Cleaning"
      />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <ActionButtons data={twoButtonsData} />
      <OurStuff hasOnlyOneSection />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <ActionButtons data={twoButtonsData} />
      <CleaningZones />
      <ServiceExclusions />
      <ActionButtons data={twoButtonsData} />
      <InSixtySeconds />
      <ValuesCleaning />
      <ActionButtons data={twoButtonsData} />
      <FrequentlyQuestions />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <WrapperMyPrice
        title="Empowering Cleaners, Elevating Homes"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized buttonText="Get a free Quote" />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
