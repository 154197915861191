import { NotFoundModule } from '../modules/NotFoundModule';
import { MainWrapper } from '../ui/MainWrapper';

export const NotFoundPage = () => {
  return (
    <MainWrapper shownBreadCrumbs>
      <NotFoundModule />
    </MainWrapper>
  );
};
