/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from 'react';
import {
  BookingPriceProviderProperties,
  BookingPriceContextData,
  ChoosedAdditionsType,
} from './types/bookingPriceContextTypes';
import {
  CleaningInformationAdditionsEntity,
  CleaningInformationEntity,
  CleaningInstructionsEntity,
  ContactInformationEntity,
  DateInformationEntity,
  FormDataEntity,
  LocationInformationEntity,
} from '../../types';

export const bookingPriceContext = createContext<BookingPriceContextData>({
  formData: {
    contactInformation: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      useSms: false,
    },
    locationInformation: {
      address: '',
      city: '',
      postal: '',
      province: '',
    },
    totalPrice: 0,
  },
  setFormData: () => {},
  removeChoosedAddition: () => {},
  setContactInformation: () => {},
  setLocationInformation: () => {},
  setDateInformation: () => {},
  setInstructionsInformation: () => {},
  setDiscountCode: () => {},
  setCleaningInformation: () => {},
  setTotalPrice: () => {},
  setRecurringPrice: () => {},
});

export const BookingPriceProvider: React.FC<BookingPriceProviderProperties> = ({
  children,
}) => {
  const [formData, setFormData] = useState<FormDataEntity>({
    dateInformation: {
      date: new Date(),
      frequencyInformation: {
        frequencyId: 0,
        frequency: '',
        percentage: '',
      },
    },
    contactInformation: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      useSms: false,
    },
    locationInformation: {
      address: '',
      city: '',
      postal: '',
      province: '',
      apt: '',
    },
    cleaningInformation: {
      id: 0,
      name: '',
      price: 0,
      discountByCode: false,
      discountByFrequency: false,
      cleaningParameters: [],
      cleaningExtras: [],
    },
    cleaningIntstructions: {
      aboutUs: '',
      flexibility: '',
      garbage: '',
      home: '',
      instructions: '',
      occasion: '',
      parking: '',
      restriction: '',
    },
    discountCode: '',
    totalPrice: 0,
    recurringPrice: null,
  });

  const removeChoosedAddition = (id: number, type: ChoosedAdditionsType) => {
    setFormData((previousData) => {
      const { cleaningInformation } = previousData;

      if (!cleaningInformation) return previousData;

      const additions = cleaningInformation[
        type
      ] as CleaningInformationAdditionsEntity[];
      const filteredAdditions = additions.filter(
        (currentAddition) => currentAddition.id !== id
      );

      return {
        ...previousData,
        cleaningInformation: {
          ...cleaningInformation,
          [type]: filteredAdditions,
        },
      };
    });
  };

  const setContactInformation = (
    contactInformation: ContactInformationEntity
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      contactInformation,
    }));
  };

  const setLocationInformation = (
    locationInformation: LocationInformationEntity
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      locationInformation,
    }));
  };

  const setDateInformation = (dateInformation: DateInformationEntity) => {
    setFormData((prevData) => ({
      ...prevData,
      dateInformation,
    }));
  };

  const setInstructionsInformation = (
    cleaningIntstructions: CleaningInstructionsEntity
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      cleaningIntstructions,
    }));
  };

  const setCleaningInformation = (
    cleaningInformation: CleaningInformationEntity
  ) => {
    setFormData((previousData) => {
      const { cleaningInformation: oldCleaningInformation } = previousData;
      if (!oldCleaningInformation) return previousData;

      return {
        ...previousData,
        cleaningInformation,
      };
    });
  };

  const setDiscountCode = (discountCode: string) => {
    setFormData((prevData) => ({
      ...prevData,
      discountCode,
    }));
  };

  const setTotalPrice = (totalPrice: number) => {
    setFormData((prevData) => ({
      ...prevData,
      totalPrice,
    }));
  };

  const setRecurringPrice = (recurringPrice: number) => {
    setFormData((prevData) => ({
      ...prevData,
      recurringPrice,
    }));
  };

  const bookingPriceSate = {
    formData,
    setFormData,
    removeChoosedAddition,
    setContactInformation,
    setLocationInformation,
    setDateInformation,
    setInstructionsInformation,
    setDiscountCode,
    setCleaningInformation,
    setTotalPrice,
    setRecurringPrice,
  };

  return (
    <bookingPriceContext.Provider value={bookingPriceSate}>
      {children}
    </bookingPriceContext.Provider>
  );
};
