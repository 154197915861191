import React from 'react';
import { Link } from 'react-router-dom';
import { BurgerTextType } from '../../types/ButtonTextType';
import { paths } from '../../../../constants';

import './styles/HeaderButton.styles.scss';

interface ButtonProps {
  text: BurgerTextType;
}

export const HeaderButton: React.FC<ButtonProps> = ({ text }) => {
  const isLoginButton = text === 'login';
  const URL = isLoginButton ? '/' : paths.book;

  return (
    <Link to={URL} className={`header__button buttons__${text}`}>
      {text}
    </Link>
  );
};
