import { companyInfo } from '../constants';
import { ContactSection } from '../modules/ContactSection';
import { IFrameToronto } from '../modules/ContactSection/components/IFrame';
import { AreaCleaning } from '../modules/AreaCleaning';
import { OurCleaningServices } from '../modules/OurCleaningServices';
import { MainWrapper } from '../ui/MainWrapper';
import { MapWrapper } from '../ui/MapWrapper';
import { TorontoText } from './constants/TorontoText';

import './styles/page.scss';

export const TorontoPage = () => {
  return (
    <MainWrapper shownBreadCrumbs>
      <div>
        <h1 className="page__title">Cleaning Services Toronto</h1>
      </div>
      <OurCleaningServices />
      <AreaCleaning
        city={TorontoText.city}
        description={TorontoText.description}
        map={<MapWrapper />}
      />
      <ContactSection
        city="Toronto"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<IFrameToronto />}
      />
    </MainWrapper>
  );
};
