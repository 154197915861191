import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { SelectForm } from '../../../../../../ui/SelectForm';
import { provinces } from '../../../../../../constants/provinces';
import { BookingField } from '../../../../types/bookingFieldset';
import { BookingText } from '../../../../ui/BookingText';
import { fields } from '../../../../constants/fields';
import { useBookingFormData } from '../../../../../../hooks/useBookingFormData';
import { LocationInformationEntity } from '../../../../../../types';

import '../../styles/WhereWhenModule.scss';

interface WhereWhenAddressProps {
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
}

export const WhereWhenAddress: React.FC<WhereWhenAddressProps> = ({
  register,
  formFields,
}) => {
  const {
    setLocationInformation,
    formData: { locationInformation },
  } = useBookingFormData();
  const provinceOptions = provinces.map((item) => item.name);

  return (
    <div className="fieldset__address-wrapper">
      <div className="fieldset__text-wrapper">
        <BookingText text="Step 2 of 5: Where and When?" option="title" />
        <BookingText
          text="1) Please enter the address of the property to be cleaned"
          option="description"
        />
      </div>
      <div className="fieldset__input-wrapper fieldset__input-wrapper_address">
        {fields.locationInformation.map((field) => {
          const { name, placeholder, type } = field;

          const handleDataAddOnBlur = () => {
            const newLocationInformation: LocationInformationEntity = {
              ...locationInformation,
              [name]: formFields[name],
            };
            setLocationInformation(newLocationInformation);
          };

          return (
            <input
              key={name}
              {...register(name as keyof BookingField, {
                onBlur: handleDataAddOnBlur,
              })}
              placeholder={placeholder}
              type={type}
              className={`where-when__input ${name}`}
            />
          );
        })}
        <SelectForm
          register={register}
          options={provinceOptions}
          formFields={formFields}
          name="province"
        />
      </div>
    </div>
  );
};
