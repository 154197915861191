import { paths } from '../../constants';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { AreaItem } from './ui/AreaItem/AreaItem';

import './styles/WhereWeWork.styles.scss';

export const WhereWeWork = () => {
  const {
    areas: { children: areasChildren },
  } = paths;

  return (
    <section className="where-we-work">
      <Container>
        <div className="where-we-work__wrapper">
          <h2 className="where-we-work__title">
            Where are you looking for cleaning services?
          </h2>
          <div className="where-we-work__links">
            <ul className="where-we-work__list">
              {Object.values(areasChildren).map((route) => {
                return <AreaItem key={route} route={route} />;
              })}
              <AreaItem isAreasLink />
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
};
