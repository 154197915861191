import { paths } from '../../../constants';
import { SlideDataType } from '../types/SlideDataType';
import cleaningTeam from '../../../assets/swiper-main/cleaning-team.jpg';
import petFriendly from '../../../assets/swiper-main/pet-friendly.jpg';
import takeCare from '../../../assets/swiper-main/take-care.jpg';
import giftCards from '../../../assets/swiper-main/gift-cards.jpg';
import rateService from '../../../assets/swiper-main/rate-service.jpg';
import flexiblePricing from '../../../assets/swiper-main/flexible-pricing.jpg';
import movingDay from '../../../assets/swiper-main/moving-day.jpg';
import christmasHoliday from '../../../assets/swiper-main/christmas-holiday.jpg';

export const slidesData: SlideDataType[] = [
  {
    id: 'professional-team',
    title: 'Your Property is in Good Hands',
    description:
      'Professional, insured, and background-checked cleaners you can rely on.',
    buttonData: {
      link: paths.book,
      text: 'Book Online',
    },
    imageSource: cleaningTeam,
  },
  {
    id: 'holiday',
    title: 'Get Ready for the Holiday Season!',
    description:
      'Book your Christmas cleaning early and enjoy a spotless home for the holidays.',
    buttonData: {
      link: paths.book,
      text: 'Reserve your Cleaning',
    },
    imageSource: christmasHoliday,
  },
  {
    id: 'pet-friendly',
    title: 'Pet-Friendly Cleaning Solutions',
    description:
      'Safe for your home, pets, and the environment. Green cleaning you can trust.',
    buttonData: {
      link: '/green-and-eco-friendly-cleaning-service',
      text: 'Book Eco-Friendly Cleaning',
    },
    imageSource: petFriendly,
  },
  {
    id: 'take-care',
    title: 'Take Care of those who Matter Most',
    description:
      'Provide peace of mind with ongoing cleaning care: Weekly, Bi-weekly, or Monthly.',
    buttonData: null,
    imageSource: takeCare,
  },
  {
    id: 'gift-cards',
    title: 'Gift Cards Available',
    description:
      'Give the gift of a clean home! Perfect for friends, family, and new homeowners.',
    buttonData: {
      link: '/gift-cards',
      text: 'Purchase Gift Card',
    },
    imageSource: giftCards,
  },
  {
    id: 'rate-service',
    title: 'Rate your Service after every Clean',
    description:
      'Always in the loop, your happiness guaranteed! Rate each clean, and if anything`s off, we`ll make it right.',
    buttonData: null,
    imageSource: rateService,
  },
  {
    id: 'flexible-pricing',
    title: 'Flexible Pricing',
    description:
      'From packages to hourly rates, customize your cleaning to best suit your family`s needs.',
    buttonData: {
      link: paths.book,
      text: 'View Pricing',
    },
    imageSource: flexiblePricing,
  },
  {
    id: 'moving-day',
    title: 'Moving Day made Easy',
    description:
      'Step into a clean, welcoming home with our move-in cleaning services.',
    buttonData: {
      link: '/move-in-move-out-cleaning',
      text: 'Book Move in / Move out Cleaning Services',
    },
    imageSource: movingDay,
  },
];
