import { useState } from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { AccordionItem } from './components/AccordionItem';
import { AccordionData } from './constants/AccordionData';

import './styles/FrequentlyQuestions.scss';

export const FrequentlyQuestions = () => {
  const [openQuestion, setOpenQuestion] = useState<string>('');

  const onClick = (idQuestion: string) => {
    if (openQuestion === idQuestion) {
      setOpenQuestion('');
    } else {
      setOpenQuestion(idQuestion);
    }
  };

  return (
    <section className="frequently-questions">
      <ContainerComponent>
        <div className="frequently-questions__wrapper">
          <h2 className="frequently-questions__title">
            Frequently Asked Questions
          </h2>
          <div className="frequently-questions__accordion">
            {AccordionData.map((item) => (
              <AccordionItem
                key={item.id}
                item={item}
                clickQuestions={onClick}
                selected={openQuestion === item.id}
              />
            ))}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
