import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { ActionButtons } from '../../modules/ActionButtons';
import { HeroSection } from '../../modules/HeroSection';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { OurStuff } from '../../modules/ThreeSectionsOfStuff';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { breakpoints } from '../../constants';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { RecurringCleaning } from '../../modules/RecurringCleaning';
import { GiftCards } from '../../modules/GiftCards';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { FormMyPrice } from '../../components/FormMyPrice';
import { SvgIconSmile } from '../../ui/SvgIcon';
import { CleaningZones } from '../../modules/CleaningZones';
import homeCleaningPageImage from '../../assets/heroSection/home-cleaning.jpg';
import { ProfessionalCleaning } from '../../modules/ProfessionalCleaning';
import { TrustedChoice } from '../../modules/TrustedChoice';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { ValuesCleaning } from '../../modules/ValuesCleaning';

export const CleaningSeniorsPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main shownBreadCrumbs>
      <HeroSection
        title="Cleaning Services For Seniors"
        subtitle="Canada’s #1 Choice for Cleaning Services for Seniors Life’s too short for cleaning, leave the mess to us!"
        imageSource={homeCleaningPageImage}
      />
      <ProfessionalCleaning
        title="Professional Cleaning Services for Seniors"
        desc="cleaning partner"
      />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <ActionButtons data={twoButtonsData} />
      <AcrossCanada />
      <OurStuff hasOnlyOneSection />
      <ActionButtons data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <ServiceExclusions />
      <ValuesCleaning />
      <CleaningZones />
      <TrustedChoice />
      <InSixtySeconds />
      <ActionButtons data={twoButtonsData} />
      <RecurringCleaning />
      <FrequentlyQuestions />
      <GiftCards />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <WrapperMyPrice
        desc="If you're not happy, we'll work to make it right!"
        title="Empowering Cleaners, Elevating Homes"
        form={<FormMyPrice isMinimized />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
