import React from 'react';
import { Link } from 'react-router-dom';
import { CleaningZonesType } from '../../type/CleaningZonesType';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';

import './styles/CleaningZonesItem.scss';

interface CleaningZonesItemProps {
  item: CleaningZonesType;
}

export const CleaningZonesItem: React.FC<CleaningZonesItemProps> = ({
  item,
}) => {
  const { img, title, tasks } = item;
  const atlText = getAltTextForImage(img);

  return (
    <div className="cleaning-zones-item">
      <div className="cleaning-zones-item__img">
        <img alt={atlText} src={img} />
      </div>
      <div className="cleaning-zones-item__title">
        <h2 className="cleaning-zones-item__title__text">{title}</h2>
      </div>
      <div className="cleaning-zones-item__container">
        <ul className="cleaning-zones-item__list">
          {tasks.map((task) => (
            <li key={task.name} className="cleaning-zones-item__task">
              <span className="cleaning-zones-item__task__icon">
                {task.icon}
              </span>
              <span className="cleaning-zones-item__task__text">
                {task.name}
              </span>
            </li>
          ))}
          {title === 'Additional Areas & Extras' && (
            <p className="cleaning-zones-item__desc">
              Additional areas and extras can be selected on our{' '}
              <Link className="cleaning-zones-item__link" to={paths.book}>
                booking page
              </Link>
            </p>
          )}
        </ul>
      </div>
    </div>
  );
};
