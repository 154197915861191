import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { paths } from '../../../constants';
import { AccordionDataType } from '../type/AccordionDataType';

export const notIncluded = [
  'Places or items higher than 12 feet.',
  'Full wash of ceilings and walls',
  'Pest removal, cleaning of bodily fluids, chemical waste, excessive mold, and other bio-hazards',
  'Fire or smoke damage, areas strongly drenched with smoking/cannabis smells',
  'Exterior windows, patios, and garages',
  'Organizing clutter/toys/books/clothes or documents',
  'Under heavy objects or furniture that require lifting or moving',
];

export const AccordionData: AccordionDataType[] = [
  {
    id: uuidv4(),
    title:
      'What factors determine the cost of Condo cleaning services in Canada?',
    content: (
      <p>
        The cost of a thorough cleaning depends on various factors. Whether your
        home has recently undergone renovations, the frequency of{' '}
        <Link to={paths.services.children.deepCleaning.path}>
          deep cleaning,
        </Link>
        or if you require a general cleaning, all play a role in determining the
        cost.{' '}
        <Link to={paths.services.children.postRenovationCleaning.path}>
          Post-construction cleaning
        </Link>
        , for example, involves detailed work to remove micro-dust accumulated
        throughout the home, impacting the pricing. Deep cleanings delve deeper
        than surface-level cleaning, taking longer and thus costing more.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: 'What Are the Costs of House Cleaning?',
    content: (
      <p>
        The cost of a thorough cleaning depends on various factors such as the
        size of your home, the number of bedrooms and bathrooms, and whether you
        require a cleaning package or hourly services. Other considerations also
        include whether your home has recently undergone renovations, the
        frequency of{' '}
        <Link to={paths.services.children.deepCleaning.path}>
          deep cleaning
        </Link>
        , or if you require a general cleaning, all of which play a role in
        determining the cost.
        <Link to={paths.services.children.postRenovationCleaning.path}>
          Post-construction cleaning
        </Link>
        , for example, involves detailed work to remove micro-dust accumulated
        throughout the home, impacting the pricing. Deep cleanings delve deeper
        than surface-level cleaning, taking longer and thus costing more.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: "What's included in a condo cleaning service?",
    content: (
      <p>
        Our deep cleaning service is tailored to your specific needs. Typical
        tasks include cleaning inside kitchen appliances like ovens and fridges,
        as well as inside cabinets and drawers. We also clean behind and inside
        appliances if they’re pulled out before our arrival. Other areas of
        focus include windows, wall wiping, and baseboard cleaning. In the
        bathroom, we perform deep scrubbing of tiles, bathtubs, toilets, sinks,
        mirrors, and floors. A thorough vacuuming and mopping conclude the job
        at the highest level.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title:
      'How much should I expect to pay for Condo cleaning services in Canada?',
    content: (
      <p>
        We offer both condo cleaning packages and hourly services. Our starting
        price for general cleaning packages is $199, while deep cleanings range
        from $249 to $999, depending on your condo’s size and condition. First
        deep cleanings typically fall between $449 and $1,000, with recurring
        cleaning services offering discounts for multiple visits per week,
        bi-weekly, or monthly schedules.
        <Link to={paths.book}>Please see our booking page</Link> to learn more.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: 'How can I book a condo cleaning service?',
    content: (
      <p>
        Booking is easy with Crystal Clear. Use our{' '}
        <Link to={paths.book}>online quote and booking page</Link> to schedule a
        cleaning date and time based on our availability. If your preferred date
        isn’t available online, feel free to call us to check for additional
        booking spots. Our online booking page offers instant quotes based on
        your home’s size and cleaning requirements, making it quick and
        convenient.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: 'Are your cleaners insured and certified?',
    content: (
      <p>
        Yes, our cleaning service is insured. Before entering clients’ homes,
        all our cleaners undergo background checks and rigorous training
        programs to provide top-notch service.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: 'Do I need to provide cleaning tools and supplies?',
    content: (
      <p>
        Our cleaners bring mops and all necessary{' '}
        <Link to="/">cleaning tools and supplies</Link> to your home. If you
        prefer cleaners to use your own tools and/or specific cleaning products,
        you can let us know.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: "What if I'm not satisfied with the cleaning?",
    content: (
      <p>
        Your satisfaction is our priority. We conduct before-and-after
        walkthroughs with the cleaner to ensure quality. If you’re not
        satisfied, please let us know, and we will make it right immediately.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: "What's your Cancellation Policy?",
    content: (
      <p>
        Flexibility is key. You can <span>cancel free of charge 24 hours</span>{' '}
        before your appointment. We understand that plans can change, and we’re
        here to accommodate your needs.
      </p>
    ),
  },
  {
    id: uuidv4(),
    title: 'What is not Included in the Cleaning Service?',
    content: (
      <div>
        <p>
          We do our best to offer as many services as possible; however, there
          are a few things we are unable to do for safety or logistical reasons.
          We are unable to clean the following:
        </p>
        <ul>
          {notIncluded.map((list) => (
            <li key={list}>{list}</li>
          ))}
        </ul>
      </div>
    ),
  },
];
