import React, { ReactElement } from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { getFormattedPhoneNumber } from '../../helpers';
import { companyInfo } from '../../constants';
import { SvgIconStore } from '../../ui/SvgIcon';

import './styles/ContactSection.scss';

interface ContactSectionProps {
  city: string;
  address: string;
  hours: string;
  officesMap: string;
  frame: ReactElement;
}

export const ContactSection: React.FC<ContactSectionProps> = ({
  city,
  address,
  hours,
  officesMap,
  frame,
}) => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="contact-section">
      <ContainerComponent>
        <div className="contact-section__container">
          <div className="contact-section__contact">
            <div className="contact-section__title">
              <h2>Contact crystal clear {city}</h2>
              <p>We would love to speak with you.</p>
            </div>
            <div className="contact-section__actions">
              <h3>Get in Touch</h3>
              <a href={`tel:${formattedPhone}`}>{companyInfo.phoneNumber}</a>
              <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
            </div>
            <div className="contact-section__address">
              <h3>Address</h3>
              <p>{address}</p>
            </div>
            <div className="contact-section__hours">
              <h3>Hours</h3>
              <p>{hours}</p>
            </div>
            <a className="contact-section__google" href={officesMap}>
              <SvgIconStore />
              <p>google maps</p>
            </a>
          </div>
          {frame}
        </div>
      </ContainerComponent>
    </section>
  );
};
