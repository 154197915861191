import { FormMyPrice } from '../components/FormMyPrice';
import { MainSwiper } from '../components/MainSwiper/MainSwiper';
import { twoButtonsData } from '../constants/buttonSectionData';
import { AcrossCanada } from '../modules/AcrossCanada';
import { ActionButtons } from '../modules/ActionButtons';
import { ContactUsSection } from '../modules/ContactUsSection';
import { WrapperMyPrice } from '../modules/WrapperMyPrice';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { SvgIconSmile } from '../ui/SvgIcon';

export const ContactUsPage = () => {
  return (
    <Main shownBreadCrumbs>
      <ContactUsSection />
      <MainSwiper />
      <AcrossCanada />
      <ActionButtons data={twoButtonsData} />
      <WrapperMyPrice
        title="Try Our Cleaning Services Today!"
        desc="If you`re not happy, we`ll work to make it right!"
        form={<FormMyPrice />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
