import React from 'react';
import { getAltTextForImage } from '../../helpers';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import cleaners from '../../assets/contact-us/crystalclear-house-cleaning-services.png';

import './styles/WrapperMyPrice.scss';

interface WrapperMyPriceProps {
  title: string;
  desc: string;
  form: React.ReactNode;
  icon?: React.ReactNode;
}

export const WrapperMyPrice: React.FC<WrapperMyPriceProps> = ({
  title,
  desc,
  form,
  icon,
}) => {
  const altText = getAltTextForImage(cleaners);
  return (
    <section className="wrapper-my-price">
      <Container>
        <div className="wrapper-my-price__wrapper">
          <div className="wrapper-my-price__content">
            <h2 className="wrapper-my-price__title">{title}</h2>
            <div className="wrapper-my-price__text">
              <p className="wrapper-my-price__desc">{desc}</p>
              {icon}
            </div>
            <div className="wrapper-my-price__form">{form}</div>
          </div>
          <div className="wrapper-my-price__img">
            <img alt={altText} src={cleaners} />
          </div>
        </div>
      </Container>
    </section>
  );
};
