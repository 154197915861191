import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../constants';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { MapWrapper } from '../../ui/MapWrapper';

import './styles/AcrossCanada.scss';
import { getFormattedPhoneNumber } from '../../helpers';

export const AcrossCanada = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);

  return (
    <section className="across-canada">
      <Container>
        <div className="across-canada__wrapper">
          <div className="across-canada__text-wrapper">
            <h2 className="across-canada__title">
              Your Trusted Choice for Cleaning Services Across Canada
            </h2>
            <p className="across-canada__description">
              Crystal Clear is proud to serve Canada&apos;s most unique and
              diverse cities. From the innovative hub of{' '}
              <Link
                to={paths.areas.children.toronto}
                className="across-canada__link"
              >
                Toronto
              </Link>{' '}
              to the warm countryside of{' '}
              <Link
                to={paths.areas.children.etobicoke}
                className="across-canada__link"
              >
                Etobicoke
              </Link>
              , the stunning natural beauty to the charming shores of{' '}
              <Link
                to={paths.areas.children.northYork}
                className="across-canada__link"
              >
                North York
              </Link>
              , the thriving suburban city of{' '}
              <Link
                to={paths.areas.children.mississauga}
                className="across-canada__link"
              >
                Mississauga
              </Link>{' '}
              to the rocky mountains of{' '}
              <Link
                to={paths.areas.children.brampton}
                className="across-canada__link"
              >
                Brampton
              </Link>
              , the historic city of{' '}
              <Link
                to={paths.areas.children.ajax}
                className="across-canada__link"
              >
                Ajax
              </Link>{' '}
              to the bustling metropolis of{' '}
              <Link
                to={paths.areas.children.milton}
                className="across-canada__link"
              >
                Milton
              </Link>
              .
            </p>
            <p className="across-canada__description">
              Booking a professional cleaning service with Crystal Clear is easy
              and convenient. You can{' '}
              <Link to={paths.book} className="across-canada__link">
                book
              </Link>{' '}
              our cleaning services online, customizing your cleaning plan to
              your specific needs with just a few clicks. You can also choose to
              give us a call at
              <Link
                className="across-canada__link"
                to={`tel:${formattedPhone}`}
              >
                {companyInfo.phoneNumber}
              </Link>
              , and our team will assist you in scheduling your cleaning!
            </p>
          </div>
          <MapWrapper />
        </div>
      </Container>
    </section>
  );
};
