import React from 'react';
import './styles/CleaningServicesTitle.scss';

interface CleaningServicesTitleProps {
  service: string;
}

export const CleaningServicesTitle: React.FC<CleaningServicesTitleProps> = ({
  service,
}) => {
  return (
    <h2 className="cleaning-services-title">
      Our {service} Cleaning Services Checklist
    </h2>
  );
};
