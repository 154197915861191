import React, { useEffect } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { discounts } from '../../../../../../constants';
import { ButtonOften } from '../UI/index';
import { BookingField } from '../../../../types/bookingFieldset';
import {
  DateInformationEntity,
  FrequencyInformationEntity,
} from '../../../../../../types';
import { useBookingFormData } from '../../../../../../hooks/useBookingFormData';

interface WhereWhenOftenProps {
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
}

export const WhereWhenOften: React.FC<WhereWhenOftenProps> = ({
  register,
  formFields,
}) => {
  const {
    setDateInformation,
    formData: { dateInformation },
  } = useBookingFormData();

  const handleDataAddOnChange = () => {
    const selectedFrequency =
      discounts.find(
        (currentDiscount) =>
          currentDiscount.id === Number(formFields.frequencyId)
      ) || null;
    const frequencyWords = selectedFrequency?.name.split(' ');
    let formattedFrequency = frequencyWords && frequencyWords[0];
    if (formattedFrequency && frequencyWords?.length === 2) {
      formattedFrequency = frequencyWords?.join(' ') as string;
    }
    const formattedFrequencyInformation: FrequencyInformationEntity = {
      frequencyId: selectedFrequency?.id as number,
      frequency: formattedFrequency as string,
      percentage: `${selectedFrequency?.value}%`,
    };

    const newDateInformation: DateInformationEntity = {
      ...dateInformation,
      frequencyInformation: formattedFrequencyInformation,
    };

    setDateInformation(newDateInformation);
  };

  useEffect(() => {
    handleDataAddOnChange();
  }, [formFields.frequencyId]);

  return (
    <div className="where-when__often">
      <h2 className="where-when__title">How often?</h2>
      <p className="where-when__desc">
        Save on recurring cleanings! Discounts are applied after the first clean
      </p>
      <ButtonOften
        discounts={discounts}
        register={register}
        formFields={formFields}
        handleDataAddOnChange={handleDataAddOnChange}
      />
    </div>
  );
};
