import { TextDescription } from './ui/TextDescription';

import './styles/Text.scss';

export const Text = () => {
  return (
    <div className="differences-between-us__text text">
      <h2 className="text__title">
        Experience the Difference with a Cleaning Company that Cares
      </h2>
      <TextDescription />
    </div>
  );
};
