import { ContainerComponent } from '../../ui/ContainerComponent';
import { SvgIconArrowRight } from '../../ui/SvgIcon';
import { RecurringCleaningItem } from './components/RecurringCleaningItem/RecurringCleaningItem';
import {
  BiWeeklyCleaning,
  MonthlyCleaning,
  OnDemandCleaning,
  WeeklyCleaning,
} from './constants/ItemsData';

import './style/RecurringCleaning.scss';

export const RecurringCleaning = () => {
  return (
    <section className="recurring-cleaning">
      <ContainerComponent>
        <h2 className="recurring-cleaning__title">
          Book a Recurring Cleaning and Save Time and Money!
        </h2>
        <div className="recurring-cleaning__container">
          <RecurringCleaningItem data={OnDemandCleaning} />
          <div>
            <SvgIconArrowRight />
          </div>
          <RecurringCleaningItem data={MonthlyCleaning} />
          <div className="recurring-cleaning_medium">
            <SvgIconArrowRight />
          </div>
          <RecurringCleaningItem data={BiWeeklyCleaning} />
          <div className="recurring-cleaning_big">
            <SvgIconArrowRight />
          </div>
          <RecurringCleaningItem data={WeeklyCleaning} />
        </div>
      </ContainerComponent>
    </section>
  );
};
