import React from 'react';
import { Link } from 'react-router-dom';
import { getPageName } from '../../../helpers';
import { getPathnames } from '../helpers/getPathnames';

interface CustomBreadcrumbsItemProps {
  path: string;
}

export const CustomBreadcrumbsItem: React.FC<CustomBreadcrumbsItemProps> = ({
  path,
}) => {
  const pathnames = getPathnames(path);

  return (
    <ol className="custom-breadcrumbs__wrapper">
      <li className="custom-breadcrumbs__item">
        <Link className="custom-breadcrumbs__link" to="/">
          Crystal Clear
        </Link>
      </li>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const namePage = getPageName(value);
        return (
          <li key={to} className="custom-breadcrumbs__link">
            <span className="custom-breadcrumbs__icon">&#10132;</span>
            {isLast ? (
              <span className="custom-breadcrumbs__link custom-breadcrumbs__current">
                {namePage}
              </span>
            ) : (
              <Link to={to} className="custom-breadcrumbs__link">
                {namePage}
              </Link>
            )}
          </li>
        );
      })}
    </ol>
  );
};
