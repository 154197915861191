import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { BookingField, FieldDefinition } from '../types/bookingFieldset';

interface BoolingCheckboxInputProperties {
  register: UseFormRegister<BookingField>;
  fieldData: FieldDefinition;
  setValue: UseFormSetValue<BookingField>;
}

export const BookingCheckboxInput: React.FC<BoolingCheckboxInputProperties> = ({
  register,
  fieldData,
  setValue,
}) => {
  const { name, type } = fieldData;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.checked);
  };

  return (
    <label className="fieldset__label-checkbox" htmlFor="reminder-checkbox">
      <input
        {...register(name, {
          onChange: handleCheckboxChange,
        })}
        className="fieldset__checkbox"
        id="reminder-checkbox"
        type={type}
      />
      Send me reminders about my booking via text message
    </label>
  );
};
