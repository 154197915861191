import { FormMyPrice } from '../../components/FormMyPrice';
import { MainSwiper } from '../../components/MainSwiper/MainSwiper';
import { breakpoints } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { GiftCards } from '../../modules/GiftCards';
import { HeroSection } from '../../modules/HeroSection';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { Reviews } from '../../modules/Reviews';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { OurStuff } from '../../modules/ThreeSectionsOfStuff';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { SvgIconSmile } from '../../ui/SvgIcon';
import homeCleaningPageImage from '../../assets/heroSection/home-cleaning.jpg';

export const CleaningServicePage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main shownBreadCrumbs>
      <HeroSection
        title="cleaning services"
        subtitle="Canada’s #1 Choice for Home Cleaning Services"
        imageSource={homeCleaningPageImage}
        desc="Book a quality cleaning service that values customer happiness more than anything. With Crestal Clear, both you and your property are in good hands!"
      />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <MainSwiper />
      <CleaningZones />
      <ServiceExclusions />
      <ActionButtons data={twoButtonsData} />
      <OurStuff hasOnlyOneSection />
      <AcrossCanada />
      <InSixtySeconds />
      <GiftCards />
      <Reviews title="Customer Reviews of Our Cleaning Services" />
      <WrapperMyPrice
        title="Try Our Cleaning Services Today!"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
