import { companyInfo } from '../constants';
import { ContactSection } from '../modules/ContactSection';
import { IFrameNorthYork } from '../modules/ContactSection/components/IFrame';
import { AreaCleaning } from '../modules/AreaCleaning';
import { GiftCards } from '../modules/GiftCards';
import { OurCleaningServices } from '../modules/OurCleaningServices';
import { MainWrapper } from '../ui/MainWrapper';
import { MapWrapper } from '../ui/MapWrapper';
import { NorthYorkText } from './constants/NorthYork';

import './styles/page.scss';
import { SpecificArea } from '../types';

export const NorthYorkPage = () => {
  return (
    <MainWrapper shownBreadCrumbs>
      <div>
        <h1 className="page__title">Cleaning Services North York</h1>
      </div>
      <OurCleaningServices />
      <AreaCleaning
        city={NorthYorkText.city}
        description={NorthYorkText.description}
        map={<MapWrapper specificArea={SpecificArea.NorthYork} />}
      />
      <ContactSection
        city="North York"
        address={companyInfo.offices['north york']}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap['north york']}
        frame={<IFrameNorthYork />}
      />
      <GiftCards />
    </MainWrapper>
  );
};
