import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { WhereWhenTimeDesc } from '../UI/index';
import { WhereWhenCalendar } from '../WhereWhenCalendar/WhereWhenCalendar';
import { getTimeOption } from '../../helpers/getTimeOption';
import { optionTimeConfig } from '../../constants/optionTimeConfig';
import { getTimeOptionType } from '../../types/getTimeOptionType';
import { TimeDropdown } from '../TimeDropdown/TimeDropdown';
import { combineDateTime } from '../../helpers/combineDateTime';
import { parseDate } from '../../helpers/parseDate';
import { BookingField } from '../../../../types/bookingFieldset';
import { useBookingFormData } from '../../../../../../hooks/useBookingFormData';
import { DateInformationEntity } from '../../../../../../types';

import 'react-calendar/dist/Calendar.css';
import '../../styles/Calendar.scss';

interface WhereWhenTimeProps {
  setValue: UseFormSetValue<BookingField>;
}

export const WhereWhenTime: React.FC<WhereWhenTimeProps> = ({ setValue }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [selectOption, setSelectOption] = useState<Array<getTimeOptionType>>();
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState('');

  const {
    setDateInformation,
    formData: { dateInformation },
  } = useBookingFormData();

  const getSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedDate) {
      setSelectOption(getTimeOption(optionTimeConfig, selectedDate));
      if (!selectedTime) {
        const timeDefault = getTimeOption(optionTimeConfig, selectedDate)[3]
          .time[0];
        setSelectedTime(timeDefault);
        setValue('serviceDate', combineDateTime(selectedDate, timeDefault));
        const newDateInformation: DateInformationEntity = {
          ...dateInformation,
          date: new Date(combineDateTime(selectedDate, timeDefault)),
        };
        setDateInformation(newDateInformation);
      } else {
        const newDateInformation: DateInformationEntity = {
          ...dateInformation,
          date: new Date(combineDateTime(selectedDate, selectedTime)),
        };
        setDateInformation(newDateInformation);
      }
    }
  }, [selectedDate]);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    const time = e.currentTarget.textContent;
    const { id } = e.currentTarget;
    if (time) {
      setSelectedTime(time);
      const selectedDay = selectOption?.find((item) => item.id === id);
      if (selectedDay) {
        const parsedDate = parseDate(selectedDay.date);
        if (parsedDate) {
          setSelectedDate(parsedDate);
          setValue('serviceDate', combineDateTime(parsedDate, time));
          const newDateInformation: DateInformationEntity = {
            ...dateInformation,
            date: new Date(combineDateTime(parsedDate, time)),
          };
          setDateInformation(newDateInformation);
        }
      }
    }
    setIsDropdownShown(false);
  };

  return (
    <div className="where-when__time">
      <p className="where-when__paragraph">
        2) Please select the date and time of our arrival.
      </p>
      <WhereWhenTimeDesc />
      <div className="where-when__select">
        <WhereWhenCalendar
          getSelectedDate={getSelectedDate}
          selectedDate={selectedDate}
        />
        <div className="where-when__select__time">
          {selectOption ? (
            <div
              className={isDropdownShown ? 'open' : 'where-when__dropdown'}
              onMouseEnter={() => setIsDropdownShown(true)}
              onMouseLeave={() => setIsDropdownShown(false)}
            >
              <TimeDropdown
                isDropdownShown={isDropdownShown}
                selectOption={selectOption}
                selectedTime={selectedTime}
                onClick={onClick}
                selectedDate={selectedDate}
              />
            </div>
          ) : (
            <input className="where-when__input" placeholder="--/--" disabled />
          )}
        </div>
      </div>
    </div>
  );
};
